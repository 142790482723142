import { Box, Grid, Slide, ThemeProvider, Typography, createTheme, responsiveFontSizes, useMediaQuery } from '@mui/material';
import './contact.css';
import { useEffect, useState } from 'react';

let theme = createTheme();
theme = responsiveFontSizes(theme);





export default function Contact() {

    const isXS = useMediaQuery(theme.breakpoints.down('sm'));

    const isSM = useMediaQuery(theme.breakpoints.down('md'));

    const isMD = useMediaQuery(theme.breakpoints.up('md'));

    const [transitionIn1, setTransitionIn1] = useState(false);
    const [transitionIn2, setTransitionIn2] = useState(false);
    const [transitionIn3, setTransitionIn3] = useState(false);
    const [transitionIn4, setTransitionIn4] = useState(false);
    const [transitionIn5, setTransitionIn5] = useState(false);

    useEffect(() => {

        setTimeout(() => { setTransitionIn1(true) }, 500);
        setTimeout(() => { setTransitionIn2(true) }, 1500);
        setTimeout(() => { setTransitionIn3(true) }, 2500);
        setTimeout(() => { setTransitionIn4(true) }, 3500);
        setTimeout(() => { setTransitionIn5(true) }, 4500);

    }, [])

    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container id="contact" justifyContent={'center'}>
                    {!isXS && <Box className="arrow-right" ></Box>}
                    <Grid item xs={10} sm={9} md={8}>
                        <Typography variant='h2' sx={{ mb: '30px' }}>Contact Us</Typography>

                        <Slide direction='up' timeout={2000} in={transitionIn1}>

                            <div>
                                <Typography variant='h6' sx={{ mb: '15px' }}>Address:</Typography>


                                <Typography sx={{ mb: '50px', width: isMD ? '50%' : '100%' }}>
                                    25 Barbados Avenue, Kingston 5, Jamaica
                                </Typography>
                            </div>

                        </Slide>

                        <Slide direction='up' timeout={2000} in={transitionIn2}>

                            <div>
                                <Typography variant='h6' sx={{ mb: '15px' }}>Telephone:</Typography>

                                <Typography sx={{ mb: '50px', width: isMD ? '75%' : '100%' }}>
                                    +1 (876) 960-8204 / +1 (876) 754-8876
                                </Typography>
                            </div>

                        </Slide>

                        <Slide direction='up' timeout={2000} in={transitionIn3}>

                            <div>
                                <Typography variant='h6' sx={{ mb: '15px' }}>Email:</Typography>

                                <Typography sx={{ mb: '50px' }}>
                                    jcampbell@citeconsult.com
                                </Typography>
                            </div>
                        </Slide>        
                    </Grid>
                </Grid>

            </ThemeProvider>

        </>

    )
}