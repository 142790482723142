import { Box, Grid, Slide, ThemeProvider, Typography, createTheme, responsiveFontSizes, useMediaQuery } from '@mui/material';
import './who-we-are.css';
import { useEffect, useState } from 'react';
import ChairmanImg from '../../images/hugh-campbell-chairman.jpg'
import CEOImg from '../../images/phillip-paulwell-director-and-ceo.jpg'
import Director1 from '../../images/john-lindo.jpeg'
import Director2 from '../../images/herman-athias-director.jpg'
import Director3 from '../../images/sekayi-campbell-director.jpg'
import Director4 from '../../images/christopher-cargill-director.jpg';
import Director5 from '../../images/claudia-davis-director.jpg';
import COOImg from '../../images/jomo-campbell-coo.jpg';
import { Waypoint } from 'react-waypoint';
let theme = createTheme();
theme = responsiveFontSizes(theme);





export default function WhoWeAre() {

    const isXS = useMediaQuery(theme.breakpoints.down('sm'));

    const isSM = useMediaQuery(theme.breakpoints.down('md'));

    const isMD = useMediaQuery(theme.breakpoints.up('md'));

    const [transitionIn1, setTransitionIn1] = useState(false);
    const [transitionIn2, setTransitionIn2] = useState(false);
    const [transitionIn3, setTransitionIn3] = useState(false);
    const [transitionIn4, setTransitionIn4] = useState(false);
    const [transitionIn5, setTransitionIn5] = useState(false);

    const [transitionIn6, setTransitionIn6] = useState(false);
    const [transitionIn7, setTransitionIn7] = useState(false);
    const [transitionIn8, setTransitionIn8] = useState(false);

    useEffect(() => {

        setTimeout(() => { setTransitionIn1(true) }, 500);
        setTimeout(() => { setTransitionIn2(true) }, 1500);

    }, [])

    const initiateTransition = (num) => {


        if (num == 1) {
            setTimeout(() => { setTransitionIn1(true) }, 500);
        }

        if (num == 2) {
            setTimeout(() => { setTransitionIn2(true) }, 500);
        }

        if (num == 3) {
            setTimeout(() => { setTransitionIn3(true) }, 500);
        }

        if (num == 4) {
            setTimeout(() => { setTransitionIn4(true) }, 500);
        }

        if (num == 5) {
            setTimeout(() => { setTransitionIn5(true) }, 500);
        }

        if (num == 6) {
            setTimeout(() => { setTransitionIn6(true) }, 500);
        }

        if (num == 7) {
            setTimeout(() => { setTransitionIn7(true) }, 500);
        }

        if (num == 8) {
            setTimeout(() => { setTransitionIn8(true) }, 500);
        }

    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid id="who-we-are" justifyContent={'center'}>
                    <Grid className="header" sx={{ pl: isXS ? '50px' : isSM ? '100px' : '150px', pr: '30px', py: '20px' }}>
                        {!isXS && <Box className="arrow-right"></Box>}
                        <Typography variant='h1'>Cite</Typography>
                        <Typography variant='h6'>CEO, Chairman, Directors and Chief Operating Officer</Typography>

                    </Grid>

                    <Grid id="profiles-1" sx={{ bgcolor: 'green' }} container className="content" justifyContent={'center'}>


                        <Grid item xs={10} sm={10} md={10}>

                            <Slide direction='up' timeout={1500} in={transitionIn1}>

                                <Grid container direction={'row'} spacing={2} sx={{ mb: '50px' }}>

                                    <Grid item >
                                        <Box sx={{ width: '200px', height: '200px', mb: '5px', bgcolor: 'white', backgroundImage: `url(${ChairmanImg})`, backgroundSize: '100%' }}>


                                        </Box>
                                        <Typography variant='h6' align='center'>Chairman</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} >
                                        <Typography>
                                            <span style={{ fontWeight: 'bold' }}>Hugh Campbell</span> is an ICT Consultant, and entrepreneur who has over
                                            40 years of experience primarily in the business of technology. He has
                                            served in both technical and executive positions at several public and
                                            private sector organizations. For the entirety of his career, he has been a
                                            champion of ICT development and innovation in Jamaica. He has served
                                            as a Member of the Board of Petrojam Limited and the Jamaica Public
                                            Service Company Limited. He is the former Chairman of the Board of
                                            Directors for Petrojam Ethanol Limited and the Board of Governors
                                            for Windward Road Primary & Junior High School. He has received the
                                            Jamaica Computer Society Lifetime Achievement Award for Excellence.
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Slide>

                            <Slide direction='up' timeout={1500} in={transitionIn2}>

                                <Grid container direction={'row'} spacing={2} >

                                    <Grid item  >
                                        <Box sx={{ width: '200px' }}>
                                            <Box sx={{ width: '200px', height: '200px', mb: '5px', bgcolor: 'white', backgroundImage: `url(${CEOImg})`, backgroundSize: '100%' }}>


                                            </Box>
                                            <Typography variant='h6' align='center'>Director & Chief Executive Officer</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} >
                                        <Typography>
                                            <span style={{ fontWeight: 'bold' }}>Phillip Paulwell CD, MP,</span> an Attorney-at-Law, is the former
                                            Minister of Science, Technology, Energy and Mining, current Member
                                            of Parliament for the Constituency of Kingston East and Port Royal and
                                            Leader of Opposition Business in the Houses of Parliament Jamaica.
                                            Mr. Paulwell is credited with the liberalization of the telecommunications
                                            sector. In 2000, he was the recipient of the Gleaner Honour
                                            Award for implementing historic measures to break the 25-year
                                            telecommunications monopoly and introduce competition in the
                                            sector. He was also recognized by international organizations including
                                            the International Telecommunications Union as well as the Caribbean
                                            Telecommunications Union. In 2015, he was accorded the Order
                                            of Distinction in the Rank of Commander (CD) for over 20 years of
                                            distinguished service to Parliament.
                                            During his tenure as Minister with portfolio responsibility for Energy,
                                            he has been credited with giving momentum to the diversification of
                                            Jamaica’s energy sources and the promotion and facilitation of millions
                                            of dollars of investment in wind and solar energy.
                                            He is a graduate of The University of the West Indies (LL. B Hons, 1986)
                                            and the Norman Manley Law School (Certificate in Legal Education,
                                            1988).
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Slide>


                        </Grid>

                    </Grid>

                    <Grid id="profiles-2" sx={{ bgcolor: '#282c34' }} container className="content" justifyContent={'center'}>

                        <Grid item xs={10} sm={10} md={10}>

                            <Waypoint onEnter={() => { initiateTransition(3) }} />
                            <Slide direction='up' timeout={1500} in={transitionIn3}>

                                <Grid container direction={'row'} spacing={2} sx={{ mb: '50px' }}>

                                    <Grid item >
                                        <Box sx={{ width: '200px', height: '200px', mb: '5px', bgcolor: 'white', backgroundImage: `url(${Director1})`, backgroundSize: '100%' }}>


                                        </Box>
                                        <Typography variant='h6' align='center'>Director</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} >
                                        <Typography>
                                            <span style={{ fontWeight: 'bold' }}>John Lindo</span> is a Professor of Parasite Epidemiology and Chair of
                                            the Department of Microbiology at The University of the West Indies,
                                            (UWI) in Jamaica. Professor Lindo is also Co-Chair of the SUNY-UWI
                                            Health Research Consortium and former Deputy Dean (Research) in
                                            the Faculty of Medical Sciences. Professor Lindo is a member of the
                                            Board of Management of the University Hospital of the West Indies.
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Slide>


                            <Waypoint onEnter={() => { initiateTransition(4) }} />
                            <Slide direction='up' timeout={1500} in={transitionIn4}>

                                <Grid container direction={'row'} spacing={2} sx={{ mb: '50px' }}>

                                    <Grid item  >
                                        <Box sx={{ width: '200px' }}>
                                            <Box sx={{ width: '200px', height: '200px', mb: '5px', bgcolor: 'white', backgroundImage: `url(${Director2})`, backgroundSize: '100%' }}>


                                            </Box>
                                            <Typography variant='h6' align='center'>Director</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} >
                                        <Typography>
                                            <span style={{ fontWeight: 'bold' }}>Herman Athias</span> has over thirty (30) years’ international leadership
                                            experience in the Information and Communication Technology industry.
                                            Over a twenty-two (22) year period, he held several senior positions at
                                            AT&T in New York/New Jersey.
                                            Since returning to Jamaica, Mr. Athias has held leadership or senior
                                            positions at Facey Commodity, CCS/Illuminat Jamaica Limited, AEROTEL,
                                            Jamaica National, eGov Jamaica Limited, The University Hospital of the
                                            West Indies among others.
                                            Herman holds a Bachelor of Science Degree in Electrical Engineering
                                            from Fairleigh Dickenson University, New Jersey and a Master’s in
                                            Business Administration from Long Island University, New York.
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Slide>


                            {/*<Waypoint onEnter={() => { initiateTransition(5) }} />*/}


                        </Grid>


                    </Grid>

                    <Grid id="profiles-3" sx={{ bgcolor: '#01579b' }} container className="content" justifyContent={'center'}>

                        <Grid item xs={10} sm={10} md={10}>

                            <Waypoint onEnter={() => { initiateTransition(6) }} />
                            <Slide direction='up' timeout={1500} in={transitionIn6}>

                                <Grid container direction={'row'} spacing={2} sx={{ mb: '50px' }}>

                                    <Grid item >
                                        <Box sx={{ width: '200px', height: '200px', mb: '5px', bgcolor: 'white', backgroundImage: `url(${Director4})`, backgroundSize: '150%', backgroundPosition: 'center' }}>


                                        </Box>
                                        <Typography variant='h6' align='center'>Director</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} >
                                        <Typography>
                                            <span style={{ fontWeight: 'bold' }}>Christopher Cargill</span> is a Partner in the Cite Consulting Services
                                            Limited. He is a successful Jamaican entrepreneur with business interest
                                            in entertainment, food services, construction and the energy sector.
                                            He is the former Chairman of the Petroleum Corporation of Jamaica,
                                            Petrojam Refinery Limited and was the Chief Negotiator on Energy
                                            issues with the Venezuelan Government on behalf of the Government
                                            of Jamaica.
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Slide>

                            <Waypoint onEnter={() => { initiateTransition(7) }} />
                            <Slide direction='up' timeout={1500} in={transitionIn7}>

                                <Grid container direction={'row'} spacing={2} sx={{ mb: '50px' }}>

                                    <Grid item  >
                                        <Box sx={{ width: '200px' }}>
                                            <Box sx={{ width: '200px', height: '200px', mb: '5px', bgcolor: 'white', backgroundImage: `url(${Director5})`, backgroundSize: '100%' }}>


                                            </Box>
                                            <Typography variant='h6' align='center'>Director</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} >
                                        <Typography>
                                            <span style={{ fontWeight: 'bold' }}>Claudia Davis</span> Ms. Claudia Davis has over 25 years of extensive
                                            experience across both public and private sectors, with expertise in
                                            Finance, Accounting, Treasury Management, Procurement, Contract
                                            Management, and Business Process Optimization. Her professional
                                            journey includes 5-year tenure at Ernst & Young, Jamaica, where she
                                            gained invaluable insights into internal and external audit practices and
                                            internal controls.
                                            With a keen aptitude for implementing advanced accounting systems,
                                            Ms. Davis took the lead in overseeing the adoption of rigorous financial
                                            policies and procedures at the Jamaica Public Service Co. She further
                                            showcased her proficiency by crafting comprehensive process flows
                                            detailing essential financial processes and associated risks as well as
                                            heading up the procurement and contract management unit.
                                            She pursued her studies at the Emile Woolf College of Accountancy
                                            in London, where she earned her ACCA accreditation (Chartered
                                            Accountant). In addition to her financial acumen, she holds a Master of
                                            Science degree in Business Management from the University of
                                            the West Indies. She is currently the Senior Manager, Finance
                                            at a major Jamaican organization.
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Slide>

                            <Waypoint onEnter={() => { initiateTransition(8) }} />
                            <Slide direction='up' timeout={1500} in={transitionIn8}>

                                <Grid container direction={'row'} spacing={2} >

                                    <Grid item  >
                                        <Box sx={{ width: '200px' }}>
                                            <Box sx={{ width: '200px', height: '200px', mb: '5px', bgcolor: 'white', backgroundImage: `url(${COOImg})`, backgroundSize: '100%' }}>


                                            </Box>
                                            <Typography variant='h6' align='center'>Chief Operating Officer</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} >
                                        <Typography>
                                            <span style={{ fontWeight: 'bold' }}>Jomo Campbell</span> is Cite Consulting Services Limited’s Chief
                                            Operating Officer. He is a trained economist with a decade of
                                            experience in financial analysis and trading. His competences include
                                            research and project management. Mr. Campbell has a proven track
                                            record of successfully leveraging technology and analytics to drive idea
                                            generation. He holds a Bachelor of Science degree in Economics from
                                            The University of the West Indies and a Jamaica Securities Certificate.
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Slide>


                        </Grid>


                    </Grid>

                </Grid>

            </ThemeProvider>

        </>

    )
}