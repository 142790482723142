import logo from './logo.svg';
import './App.css';
import Header from './layout/header';
import Footer from './layout/footer';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Fab, Fade, Grid, Slide, ThemeProvider, Tooltip, Typography, createTheme, responsiveFontSizes, useMediaQuery } from '@mui/material';
import HomeImg from './images/cite-home-page.jpg'
import { useEffect, useState } from 'react';

let theme = createTheme();
//theme = responsiveFontSizes(theme);

function App() {
  let location = useLocation();

  let navigate = useNavigate();

  const isXS = useMediaQuery(theme.breakpoints.down('sm'));

  const isSM = useMediaQuery(theme.breakpoints.down('md'));

  const isMD = useMediaQuery(theme.breakpoints.up('md'));

  const [transitionIn1, setTransitionIn1] = useState(false);
  const [transitionIn2, setTransitionIn2] = useState(false);

  useEffect(() => {

    if (location.pathname == "/") {
      setTimeout(() => { setTransitionIn1(true) }, 500);
      setTimeout(() => { setTransitionIn2(true) }, 1000);
    } else {
      setTransitionIn1(false);
      setTransitionIn2(false);
    }


  }, [location.pathname])

  const goToFootprintCalculator = () => {
    navigate('/carbon-footprint');
  }


  return (
    <div className="App">
      <Header />
      <Box className="main" sx={{ pt: "80px", height: 'calc(100vh - 120px)' }}>
        <Outlet />
        {location.pathname == "/" &&
          <ThemeProvider theme={theme}>
            <Grid container alignItems={'stretch'} sx={{ height: '100%' }}>
              <Grid item xs={12} sm={12} md={6}>
                <Box sx={{ backgroundImage: `url(${HomeImg})`, backgroundSize: '100%', width: '100%', height: '100%' }}></Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Grid container sx={{ minHeight: '500px' }} alignItems={'center'} justifyContent={'center'}>

                  <Grid item>
                    <Fade in={transitionIn1} direction='up' timeout={2000}>
                      <Typography variant='h1' sx={{ letterSpacing: '.7rem', fontSize: isMD ? '9.5rem' : '7rem' }}>Cite</Typography>
                    </Fade>

                    <Fade in={transitionIn2} direction='up' timeout={2000}>
                      <Typography variant='h2' sx={{ fontWeight: '300', fontSize: isMD ? '3.7rem' : '2rem' }}>Eco Solutions Ltd.</Typography>
                    </Fade>


                    <Box sx={{ mt: '25px' }}>
                      <Tooltip title="Calculate Carbon Footprint">
                        <Fab id="carbon-footprint-btn" onClick={goToFootprintCalculator} variant='extended'>
                          <Box id="carbon-footprint-icon">
                            <Box id="carbon-footprint-icon-inner">
                            </Box>
                          </Box>
                          Calculate Footprint
                        </Fab>
                      </Tooltip>
                    </Box>



                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          </ThemeProvider>

        }
        <Footer />
      </Box>

    </div>
  );
}

export default App;
