import { Box, Grid, Slide, ThemeProvider, Typography, createTheme, responsiveFontSizes, useMediaQuery } from '@mui/material';
import './about.css';
import { useEffect, useState } from 'react';

let theme = createTheme();
theme = responsiveFontSizes(theme);





export default function About() {

    const isXS = useMediaQuery(theme.breakpoints.down('sm'));

    const isSM = useMediaQuery(theme.breakpoints.down('md'));

    const isMD = useMediaQuery(theme.breakpoints.up('md'));

    const [transitionIn1, setTransitionIn1] = useState(false);
    const [transitionIn2, setTransitionIn2] = useState(false);
    const [transitionIn3, setTransitionIn3] = useState(false);
    const [transitionIn4, setTransitionIn4] = useState(false);
    const [transitionIn5, setTransitionIn5] = useState(false);

    useEffect(() => {

        setTimeout(() => { setTransitionIn1(true) }, 500);
        setTimeout(() => { setTransitionIn2(true) }, 1500);
        setTimeout(() => { setTransitionIn3(true) }, 2500);
        setTimeout(() => { setTransitionIn4(true) }, 3500);
        setTimeout(() => { setTransitionIn5(true) }, 4500);

    }, [])

    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container id="about" justifyContent={'center'}>
                    {!isXS && <Box className="arrow-right" ></Box>}
                    <Grid item xs={10} sm={9} md={8}>
                        <Typography variant='h2' sx={{ mb: '30px' }}>About Us</Typography>

                        <Slide direction='up' timeout={2000} in={transitionIn1}>

                            <div>
                                <Typography variant='h6' sx={{ mb: '15px' }}>WHO WE ARE</Typography>


                                <Typography sx={{ mb: '50px', width: isMD ? '50%' : '100%' }}>We are a boutique carbon offsets
                                    consultancy. We offer advisory services
                                    for clients seeking to enter the
                                    voluntary carbon market. In addition,
                                    we prepare and submit applications
                                    for clients seeking international
                                    registration of carbon offset projects and
                                    broker the sale of registered carbon offsets.</Typography>
                            </div>

                        </Slide>

                        <Slide direction='up' timeout={2000} in={transitionIn2}>

                            <div>
                                <Typography variant='h6' sx={{ mb: '15px' }}>WE UNDERSTAND THE IMPORTANCE OF FIGHTING
                                    CLIMATE CHANGE</Typography>

                                <Typography sx={{ mb: '50px', width: isMD ? '75%' : '100%' }}>We recognize that climate change poses a serious threat to the Caribbean
                                    region and provides an opportunity for regional climate action. Our core services
                                    facilitate increased investment in projects that reduce/remove greenhouse gas emissions.</Typography>
                            </div>

                        </Slide>

                        <Slide direction='up' timeout={2000} in={transitionIn3}>

                            <div>
                                <Typography variant='h6' sx={{ mb: '15px' }}>WE SUPPORT CLIENTS TRYING TO MAKE SENSE OF AN EVOLVING PROCESS</Typography>

                                <Typography sx={{ mb: '50px' }}>We have the requisite understanding of the carbon offset market and assist clients in the following ways:
                                    building capacity around carbon offset issuance and trading; learning how to satisfy carbon offset registration
                                    requirements; completing the registration process; and selling certified carbon offsets.</Typography>
                            </div>

                        </Slide>


                        <Slide direction='up' timeout={2000} in={transitionIn4}>

                            <div>
                                <Typography variant='h6' sx={{ mb: '15px' }}>WE ARE A JAMAICAN FIRM WITH GLOBAL REACH</Typography>

                                <Typography sx={{ mb: '50px' }}>Our principals are proudly Jamaican, with indepth knowledge of the local opportunities and constraints. We have
                                    access to a clientele of international customers that are seeking to offset their carbon footprint by setting carbon
                                    offsets. We act as the broker to connect these clients with relevant stakeholders in the Caribbean.</Typography>
                            </div>

                        </Slide>

                        <Slide direction='up' timeout={2000} in={transitionIn5}>

                            <div>

                                <Typography variant='h6' sx={{ mb: '15px' }}>WE HAVE THE CAPACITY AND RESOURCES TO DELIVER</Typography>

                                <Typography sx={{ mb: '50px' }}>We have a diverse team of experts in Energy, Economics, Finance, Commodities Trading, Law and Risk
                                    Management. Our team has significant experience at the ministerial level, as well as in academic, public and
                                    private sector organizations in the Caribbean and wider global market</Typography>

                            </div>

                        </Slide>

                    </Grid>
                </Grid>

            </ThemeProvider>

        </>

    )
}