import { Box, Grid } from "@mui/material";





export default function Footer() {

    return (
        <Grid container sx={{height: "40px", width: '100%', bgcolor: "grey"}} >
            {/*<Box sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#101010' : 'grey.600',
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.50'),
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                p: 2,
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                position: 'absolute',
                top: "-50%",
                left: '50%',
                zIndex: 'tooltip',
            }}>
                Icon

        </Box>*/}

        </Grid>
    )

}