import { Box, Grid, Slide, ThemeProvider, Typography, createTheme, responsiveFontSizes, useMediaQuery } from '@mui/material';
import './statement.css';
import { useEffect, useState } from 'react';

let theme = createTheme();
theme = responsiveFontSizes(theme);





export default function Statement() {

    const isXS = useMediaQuery(theme.breakpoints.down('sm'));

    const isSM = useMediaQuery(theme.breakpoints.down('md'));

    const isMD = useMediaQuery(theme.breakpoints.up('md'));

    const [transitionIn1, setTransitionIn1] = useState(false);
    const [transitionIn2, setTransitionIn2] = useState(false);
    const [transitionIn3, setTransitionIn3] = useState(false);

    useEffect(() => {

        setTimeout(() => { setTransitionIn1(true) }, 500);
        setTimeout(() => { setTransitionIn2(true) }, 1000);
        setTimeout(() => { setTransitionIn3(true) }, 1500);

    }, [])

    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container id="statement" justifyContent={'center'}>
                    {!isXS && <Box className="arrow-right"></Box>}
                    <Grid item xs={10} sm={8} md={7}>


                        <Slide direction='up' timeout={2000} in={transitionIn1}>

                            <div>
                                <Typography variant='h6' sx={{ mb: '15px' }}>WE APPROACH CONSULTING THROUGH PARTNERSHIPS AND COLLABORATION</Typography>


                                <Typography sx={{ mb: '80px' }}>We believe in the importance of finding a solution that fits our client. We are, first and foremost, listeners
                                    as this is the most effective way for us to understand our clients’ needs and priorities. Having gained a full
                                    understanding of our clients’ objectives, we focus on creating an optimal solution, utilizing our combined
                                    experience, network of contacts and proactive, innovative thinking.</Typography>
                            </div>

                        </Slide>

                        <Slide direction='up' timeout={2000} in={transitionIn2}>

                            <div>
                                <Typography variant='h4' sx={{ mb: '15px' }} align='center'>Vision Statement</Typography>

                                <Typography sx={{ mb: '80px' }} align='center'>To make the planet better by the reduction and removal
                                    of greenhouse gas emissions</Typography>
                            </div>

                        </Slide>

                        <Slide direction='up' timeout={2000} in={transitionIn3}>

                            <div>
                                <Typography variant='h4' sx={{ mb: '15px' }} align='center'>Mission Statement</Typography>

                                <Typography sx={{ mb: '30px' }} align='center'>We deliver greenhouse solutions through partnership
                                    and collaboration
                                </Typography>
                            </div>

                        </Slide>


                    </Grid>
                </Grid>

            </ThemeProvider>

        </>

    )
}