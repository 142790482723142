import React from "react";
import { Grid, useMediaQuery, Typography, Box, createTheme, responsiveFontSizes, ThemeProvider, CircularProgress } from "@mui/material";
import './carbon-footprint.css'
import FootprintIcon from '../../images/footprint-svgrepo-com.svg';
import LeafIcon from '../../images/leaf-svgrepo-com.svg';
import Calculator from "../../components/Tabs/calculator";


export default function CarbonFootprint() {

    let theme = createTheme();
    theme = responsiveFontSizes(theme);


    const isXS = useMediaQuery(theme.breakpoints.down('sm'));

    const isSM = useMediaQuery(theme.breakpoints.down('md'));

    const isMD = useMediaQuery(theme.breakpoints.up('md'));



    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid id="carbon-footprint" >
                    <Grid container className="header" justifyContent={'center'} sx={{ pl: isXS ? '30px' : isSM ? '100px' : '150px', pr: '30px', py: '20px' }}>
                        <Grid item >
                            {!isXS && <Box className="arrow-right"></Box>}
                            <Grid container direction={'row'} justifyContent={'center'} >
                                <Grid item>
                                    <img src={FootprintIcon} width={isXS ? 30 : isSM ? 50 : 70}></img>
                                </Grid>

                                <Grid item>
                                    <Typography className="title" align="left" variant='h2' sx={{ mb: '30px', color: "#1CA941" }}>Carbon Footprint</Typography>
                                </Grid>

                                <Grid item sx={{ ml: isXS ? '5px' : '10px' }}>
                                    <img src={LeafIcon} width={isXS ? 15 : isSM ? 25 : 30} ></img>
                                </Grid>
                            </Grid>
                            <Box id="footprint-calculator" sx={{ display: 'flex', position: 'relative' }}>
                                {/*<CircularProgress className="progress" color="success" />*/}
                                <Calculator/>
                                {/*<iframe width={isXS ? "300" : isSM ? "500" : "700"} height={isXS ? "2000" : isSM ? "1500" : "1000"} frameborder="0" marginwidth="0" marginheight="0" scrolling="no" src="https://calculator.carbonfootprint.com/calculator.aspx"></iframe> */}
                            </Box>
                            
                        </Grid>



                    </Grid>

                </Grid>
            </ThemeProvider>
        </>
    )
}

