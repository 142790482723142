import { AppBar, Box, Button, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CITELogo from '../images/cite-logo.jpg'
import { useEffect, useState } from "react";
import { Menu as MenuIcon } from "@mui/icons-material";





export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [location.pathname])



    const navItems = [
        {
            title: 'Home',
            path: "/"
        },
        {
            title: 'About',
            path: "/about"
        },
        {
            title: 'Statement',
            path: "/statement"
        },
        {
            title: 'Who We Are',
            path: "/who-we-are"
        },
        {
            title: 'Carbon Footprint',
            path: "/carbon-footprint"
        },
        {
            title:'Contact Us',
            path:'/contact'
        }
    ];



    const goToRoute = (item, event) => {

        navigate(item.path)
        handleCloseNavMenu();

    }

    return (
        <AppBar component={"nav"} sx={{ bgcolor: "#FFF", color: "#000" }}>
            <Toolbar>
                <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ py: "5px" }}>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, mr: "10px" }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit">
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                    >
                                        {navItems.map((item) => (
                                            <MenuItem key={item} onClick={(e) => goToRoute(item, e)}>
                                                <Typography textAlign="center">{item.title}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>

                            </Grid>

                            <Grid item>
                                <img width={'125px'} src={CITELogo} onClick={(e) => goToRoute(navItems[0], e) } style={{cursor: 'pointer'}}/>
                            </Grid>
                        </Grid>



                    </Grid>

                    <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {navItems.map((item) => (
                            <Button key={item} onClick={(e) => goToRoute(item, e)} sx={{ color: '#000' }}>
                                {item.title}
                            </Button>
                        ))}

                    </Grid>


                </Grid>
            </Toolbar>

        </AppBar>
    )
}