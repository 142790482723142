import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router';
import About from './pages/about/about';
import Statement from './pages/statement/statement';
import WhoWeAre from './pages/who-we-are/who-we-are';
import CarbonFootprint from './pages/carbon-footprint/carbon-footprint';
import Contact from './pages/contact/contact';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [{
      path: "/about",
      element: <About />
    },{
      path: "/statement",
      element: <Statement />
    },
    {
      path: "/who-we-are",
      element: <WhoWeAre />
    },
    {
      path: "/carbon-footprint",
      element: <CarbonFootprint />
    },
    {
      path: "/contact",
      element: <Contact />
    }]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
