import { Box, Button, Input, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

/*Car formula
* (miles/mpg) * fuel type
*/
const kmTom = 0.621371
const mTokm = 1.60934

const flightEF = 0.115

const fuelType ={
    G:8.89,
    D:10.16
}

const defaultCarError = {
    unit:false,
    distance:false,
    mpg:false,
    fuel:false
}
const defaultError = {
    unit:false,
    distance:false,
}
const defaultCar = {
    color:"grey",
    hidden:true,
    unit:"",
    distance:"",
    mpg:"",
    fuel:""
}
const defaultFlight ={
    color:"grey",
    hidden:true,
    unit:"",
    distance:""
}
const defaultEnabledFields ={
    car:false,
    bus:false,
    flight:false,
    train:false
}
const defaultTrain ={
    color:"grey",
    hidden:true,
    unit:"",
    distance:""
}

const isNumeric = (value) => /^[0-9]+$/.test(value) || value === "";

export default function TransportationTab(){
    const [car,setCar] = useState({...defaultCar})
    const [carError,setCarError] = useState({...defaultCarError})
    const [bus,setBus] = useState({...defaultCar})
    const [busError,setBusError] = useState({...defaultError})
    const [train,setTrain] = useState({...defaultTrain})
    const [flight,setFlight] = useState({...defaultFlight})
    const [flightError,setFlightError] = useState({...defaultError})
    const [enabledField, setEnabledField] = useState({...defaultEnabledFields})
    const [cf,setCf] = useState(0)
    const [hideCalculatedCF, setHideCalculatedCF] = useState(true)
    const onBtnClick= (e)=>{
        let id = e.currentTarget.id.split('_')[0]
        switch(id){
            case "car":
                if(car.hidden){
                    setCar({...car,hidden:false,color:"#008000"})
                    setEnabledField({...enabledField,car:true})
                }else{
                    setCar({...car,hidden:true,color:"grey"})
                    setEnabledField({...enabledField,car:false})
                }
                break;
            case "bus":
                if(bus.hidden){
                    setBus({...bus,hidden:false,color:"#008000"})
                    setEnabledField({...enabledField,bus:true})
                }else{
                    setBus({...bus,hidden:true,color:"grey"})
                    setEnabledField({...enabledField,bus:false})
                }
                break;
            case "train":
                if(train.hidden){
                    setTrain({...train,hidden:false,color:"#008000"})
                    setEnabledField({...enabledField,train:true})
                }else{
                    setTrain({...train,hidden:true,color:"grey"})
                    setEnabledField({...enabledField,train:false})
                }
                break;
            case "flight":
                if(flight.hidden){
                    setFlight({...flight,hidden:false,color:"#008000"})
                    setEnabledField({...enabledField,flight:true})
                }else{
                    setFlight({...flight,hidden:true,color:"grey"})
                    setEnabledField({...enabledField,flight:false})
                }
                break;
            default:
        }
    }
    const onChangeCarUnit = (e) => {
        setCar({...car,unit:e.target.value})
    }
    const onChangeCarFuel = (e) => {
        setCar({...car,fuel:e.target.value})
    }
    const calculateCFVehicle = () =>
    {
        let footprint = 0       
        footprint += calculateCarFootprint(footprint)
        footprint += calculateBusFootprint(footprint)
        footprint += calculateFlightFootprint(footprint)
        if(footprint > 0){
            setCf(footprint)
            setHideCalculatedCF(false)
        }else
            setHideCalculatedCF(true)           
    }
    const setCarErrorFields = () => {
        let carErr = {}
        if(car.unit.length<1)
            carErr['unit'] = true
        else
            carErr['unit'] = false
        if(car.distance.length<1)
            carErr['distance'] = true
        else
            carErr['distance'] = false
        if(car.mpg.length<1)
            carErr['mpg'] = true
        else
            carErr['mpg'] = false
        if(car.fuel.length<1)
            carErr['fuel']=true
        else
            carErr['fuel']=false
        setCarError({...carError,...carErr})
    }
    const setBusErrorFields = () => {
        let busErr = {}
        if(bus.unit.length<1)
            busErr['unit'] = true
        else
            busErr['unit'] = false
        if(bus.distance.length<1)
            busErr['distance'] = true
        else
            busErr['distance'] = false
        setBusError({...busError,...busErr})
    }
    const setFlightErrorFields = () => {
        let flightErr = {}
        if(flight.unit.length<1)
            flightErr['unit'] = true
        else
            flightErr['unit'] = false
        if(flight.distance.length<1)
            flightErr['distance'] = true
        else
            flightErr['distance'] = false
        setFlightError({...flightError,...flightErr})
    }
    const setCarDistance = (e) => {
        if(isNumeric(e.target.value))
            setCar({...car,distance:e.target.value})
    }
    const setCarMPG = (e) => {
        if(isNumeric(e.target.value))
            setCar({...car,mpg:e.target.value})
    }
    const setBusDistance = (e) => {
        if(isNumeric(e.target.value))
            setBus({...bus,distance:e.target.value})
    }
    const setBusUnit = (e) => {
        setBus({...bus,unit:e.target.value})
    }
    const setFlightDistance = (e) => {
        if(isNumeric(e.target.value))
            setFlight({...flight,distance:e.target.value})
    }
    const setFlightUnit = (e) => {
        setFlight({...flight,unit:e.target.value})
    }
    const calculateCarFootprint = (footprint) => {
        if(enabledField.car){
            if(car.fuel.length>0 &&car.distance.length>0&&car.mpg.length>0&&car.unit.length>0){
                setCarError({...defaultCarError})
                let distance = 0
                if(car.unit === "KM")
                    distance = parseFloat(car.distance)* kmTom;
                else
                    distance = parseFloat(car.distance)
                footprint = (distance / parseFloat(car.mpg)) * fuelType[car.fuel]
            }else{
                setCarErrorFields()                
            }
        }
        return footprint
    }
    const calculateBusFootprint = (footprint) => {
        if(enabledField.bus){
            if(bus.distance.length>0 && bus.unit.length>0){
                setBusError({...defaultError})
                let distance = 0
                if(bus.unit === "KM")
                    distance = parseFloat(bus.distance)* kmTom;
                else
                    distance = parseFloat(bus.distance)
                footprint = (distance / 16) * fuelType["D"]
            }else{
                setBusErrorFields()                
            }
        }
        return footprint
    }
    const calculateFlightFootprint = (footprint) => {        
        if(enabledField.flight){            
            if(flight.distance.length>0 && flight.unit.length>0){
                setFlightError({...defaultError})
                let distance = 0
                if(flight.unit === "KM")
                    distance = parseFloat(flight.distance)* mTokm;
                else
                    distance = parseFloat(flight.distance)
                footprint = distance * flightEF
            }else{
                setFlightErrorFields()                
            }
        }
        return footprint
    }
    const resetApp = () => {
        setCar({...defaultCar})
        setCarError({...defaultCarError})
        setBus({...defaultCar})
        setBusError({...defaultError})
        setTrain({...defaultTrain})
        setFlight({...defaultFlight})
        setFlightError({...defaultError})
        setEnabledField({...defaultEnabledFields})
        setCf(0)
        setHideCalculatedCF(true)
    }
    const requiredWarning = () => {

        return (flightError.distance || flightError.unit || busError.distance || busError.unit
                || carError.distance || carError.unit || carError.fuel || carError.mpg) && (enabledField.car
                    || enabledField.bus || enabledField.flight)
    }
    const setCarColour = () => {
        return carError.distance || carError.fuel || carError.mpg || carError.unit?'red':'#008000'
    }
    const setBusColour = () => {
        return busError.distance || busError.unit?'red':'#008000'
    }
    const setFlightColour = () => {
        return flightError.distance || flightError.unit?'red':'#008000'
    }
    return(
        <>
            <Box sx={{width:"70vw",textAlign:"start",backgroundColor:'white',padding:'10px',borderRadius:'10px'}}>
                <Typography gutterBottom mt={2} color={"black"}>Please select a type to display/hide fields.</Typography>
                <Box sx={{mb:3,border:"1px solid #008000",width:'fit-content',borderRadius:'5px'}}>
                    <Tooltip title="Car">
                        <Button id="car_btn" onClick={onBtnClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={car.color} viewBox="0 0 16 16">
                                <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679q.05.242.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.8.8 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2m10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2M6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2zM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17s3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"/>
                            </svg>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Bus">
                        <Button id="bus_btn" onClick={onBtnClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={bus.color}  viewBox="0 0 16 16">
                                <path d="M5 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0m8 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-6-1a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zm1-6c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m0-1c-1.837 0-3.353.107-4.448.22a.5.5 0 1 1-.104-.994A44 44 0 0 1 8 2c1.876 0 3.426.109 4.552.226a.5.5 0 1 1-.104.994A43 43 0 0 0 8 3"/>
                                <path d="M15 8a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1V2.64c0-1.188-.845-2.232-2.064-2.372A44 44 0 0 0 8 0C5.9 0 4.208.136 3.064.268 1.845.408 1 1.452 1 2.64V4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v3.5c0 .818.393 1.544 1 2v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5V14h6v1.5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2c.607-.456 1-1.182 1-2zM8 1c2.056 0 3.71.134 4.822.261.676.078 1.178.66 1.178 1.379v8.86a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 11.5V2.64c0-.72.502-1.301 1.178-1.379A43 43 0 0 1 8 1"/>
                            </svg>
                        </Button>
                    </Tooltip>
                    {/*<Tooltip title="Train">
                        <Button id="train_btn" onClick={onBtnClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={train.color} viewBox="0 0 16 16">
                                <path d="M10.621.515C8.647.02 7.353.02 5.38.515c-.924.23-1.982.766-2.78 1.22C1.566 2.322 1 3.432 1 4.582V13.5A2.5 2.5 0 0 0 3.5 16h9a2.5 2.5 0 0 0 2.5-2.5V4.583c0-1.15-.565-2.26-1.6-2.849-.797-.453-1.855-.988-2.779-1.22ZM6.5 2h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1m-2 2h7A1.5 1.5 0 0 1 13 5.5v2A1.5 1.5 0 0 1 11.5 9h-7A1.5 1.5 0 0 1 3 7.5v-2A1.5 1.5 0 0 1 4.5 4m.5 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0m8 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3-1a1 1 0 1 1 0 2 1 1 0 0 1 0-2M4 5.5a.5.5 0 0 1 .5-.5h3v3h-3a.5.5 0 0 1-.5-.5zM8.5 8V5h3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z"/>
                            </svg>
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="Flight">
                        <Button id="flight_btn" onClick={onBtnClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={flight.color}  viewBox="0 0 16 16">
                                <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0"/>
                            </svg>
                        </Button>
                    </Tooltip>                    
                </Box>
                <Typography variant="h6" sx={{color:'red',mb:5}} hidden={!requiredWarning()}>Please input data into required fields</Typography>
                <Box id="car_fields_container" hidden={car.hidden} sx={{mb:2}}>
                    <div style={{display:"flex",marginTop:"auto",marginBottom:"auto",color:"black"}}>
                        <Input error={carError.distance} id="car_inp" placeholder="Distance Travelled" sx={{width:"30%",mr:3}} value={car.distance} onChange={setCarDistance}/> 
                        <Select
                            error={carError.unit}
                            variant="standard"
                            id="car_units"
                            sx={{width:"20%",mr:3}}
                            value={car.unit}
                            displayEmpty
                            onChange={onChangeCarUnit}                        
                        >
                            <MenuItem value="" disabled>Unit</MenuItem>
                            <MenuItem value="KM">Kilometers</MenuItem>
                            <MenuItem value="M">Miles</MenuItem>
                        </Select>
                        <Select
                            error={carError.fuel}
                            variant="standard"
                            id="car_fuelType"
                            sx={{width:"20%",mr:3}}
                            value={car.fuel}
                            displayEmpty
                            onChange={onChangeCarFuel}
                        >
                            <MenuItem value="" disabled>Fuel Type</MenuItem>
                            <MenuItem value="G">Gas</MenuItem>
                            <MenuItem value="D">Diesel</MenuItem>
                        </Select>
                        <Tooltip title="Miles Per Gallon">
                            <Input error={carError.mpg} id="car_mpg" placeholder="(MPG)" sx={{width:"20%",mr:3}} value={car.mpg} onChange={setCarMPG}/>                                                                             
                        </Tooltip>      
                        <svg style={{marginTop:"auto", marginBottom:"auto"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={setCarColour()}  viewBox="0 0 16 16">
                            <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679q.05.242.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.8.8 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2m10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2M6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2zM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17s3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"/>
                        </svg>  
                    </div>                                        
                </Box>
                <Box id="bus_fields_container" hidden={bus.hidden} sx={{mb:2}}>
                    <Input error={busError.distance} id="bus_inp" placeholder="Distance Travelled" sx={{width:"50%",mr:5}} value={bus.distance} onChange={setBusDistance}/> 
                    <Select
                        variant="standard"
                        id="bus_units"
                        sx={{width:"25%",mr:3}}
                        value={bus.unit}
                        displayEmpty
                        error={busError.unit}
                        onChange={setBusUnit}
                    >
                        <MenuItem value="" disabled>Unit</MenuItem>
                        <MenuItem value="KM">Kilometers</MenuItem>
                        <MenuItem value="M">Miles</MenuItem>
                    </Select>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={setBusColour()} viewBox="0 0 16 16">
                        <path d="M5 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0m8 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-6-1a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zm1-6c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m0-1c-1.837 0-3.353.107-4.448.22a.5.5 0 1 1-.104-.994A44 44 0 0 1 8 2c1.876 0 3.426.109 4.552.226a.5.5 0 1 1-.104.994A43 43 0 0 0 8 3"/>
                        <path d="M15 8a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1V2.64c0-1.188-.845-2.232-2.064-2.372A44 44 0 0 0 8 0C5.9 0 4.208.136 3.064.268 1.845.408 1 1.452 1 2.64V4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v3.5c0 .818.393 1.544 1 2v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5V14h6v1.5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2c.607-.456 1-1.182 1-2zM8 1c2.056 0 3.71.134 4.822.261.676.078 1.178.66 1.178 1.379v8.86a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 11.5V2.64c0-.72.502-1.301 1.178-1.379A43 43 0 0 1 8 1"/>
                    </svg>
                </Box>
                <Box id="train_fields_container" hidden={train.hidden} sx={{mb:2}}>
                    <Input id="train_inp" placeholder="Distance Travelled" type="number" sx={{width:"50%",mr:5}} value={train.distance}/> 
                    <Select
                        variant="standard"
                        labelId="demo-customized-select-label"
                        id="train_units"
                        sx={{width:"25%",mr:3}}
                        value={train.unit}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Unit</MenuItem>
                        <MenuItem value="KM">Kilometers</MenuItem>
                        <MenuItem value="M">Miles</MenuItem>
                    </Select>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={train.color} viewBox="0 0 16 16">
                        <path d="M10.621.515C8.647.02 7.353.02 5.38.515c-.924.23-1.982.766-2.78 1.22C1.566 2.322 1 3.432 1 4.582V13.5A2.5 2.5 0 0 0 3.5 16h9a2.5 2.5 0 0 0 2.5-2.5V4.583c0-1.15-.565-2.26-1.6-2.849-.797-.453-1.855-.988-2.779-1.22ZM6.5 2h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1m-2 2h7A1.5 1.5 0 0 1 13 5.5v2A1.5 1.5 0 0 1 11.5 9h-7A1.5 1.5 0 0 1 3 7.5v-2A1.5 1.5 0 0 1 4.5 4m.5 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0m8 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3-1a1 1 0 1 1 0 2 1 1 0 0 1 0-2M4 5.5a.5.5 0 0 1 .5-.5h3v3h-3a.5.5 0 0 1-.5-.5zM8.5 8V5h3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z"/>
                    </svg>
                </Box>
                <Box id="flight_fields_container" hidden={flight.hidden}>
                    <Input error={flightError.distance} id="flight_inp" placeholder="Distance Travelled" sx={{width:"50%",mr:5}} value={flight.distance} onChange={setFlightDistance}/> 
                    <Select
                        variant="standard"
                        labelId="demo-customized-select-label"
                        id="flight_units"
                        sx={{width:"25%",mr:3}}
                        value={flight.unit}
                        displayEmpty
                        error={flightError.unit}
                        onChange={setFlightUnit}
                    >
                        <MenuItem value="" disabled>Unit</MenuItem>
                        <MenuItem value="KM">Kilometers</MenuItem>
                        <MenuItem value="M">Miles</MenuItem>
                    </Select>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={setFlightColour()} viewBox="0 0 16 16">
                        <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0"/>
                    </svg>
                </Box>
                <Box sx={{width:"100%",textAlign:"center",marginTop:"20px"}} >
                    <Button sx={{border:1,marginRight:'10px',color:"#008000"}}  onClick={calculateCFVehicle}
                        disabled={!enabledField.car && !enabledField.bus && !enabledField.flight}>
                            Calculate
                    </Button>
                    <Button sx={{border:1,color:'red'}} onClick={resetApp}
                        disabled={!enabledField.car && !enabledField.bus && !enabledField.flight}>
                            Reset
                    </Button>
                </Box>
                <Box sx={{width:'100%',color:'black'}}>
                    <p>Carbon footprint calculations are typically based on annual emissions from the previous 12 months.</p>
                    <Typography hidden={hideCalculatedCF} variant="h6" sx={{fontWeight:'bold'}}>Calculated carbon footprint: {cf.toFixed(2)} Kg CO2</Typography>
                </Box>                    
            </Box>
        </>
    )
}